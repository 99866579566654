import axios from 'axios'

const path = '/miembros'

export function getAllMiembros (params) {
  return axios.get(path, { params })
  .then(response => {
    return response.data
  })
}

export function getMiembroByToken (token) {
  return axios.get('/miembrobytoken?token=' + token)
  .then(response => {
    return response.data
  })
}

export function getMiembroByEmail (email) {
  return axios.get('/miembrobyemail?email=' + btoa(email))
  .then(response => {
    return response.data
  })
}

export function buscarEmailDuplicado (email) {
  return axios.get('/emailduplicado?email=' + btoa(email))
  .then(response => {
    return response.data
  })
}

export function add (miembro) {
  return axios.post(path, miembro)
  .then(response => {
    return response.data
  })
}

export function update (id, miembro) {
  return axios.put(path + '/' + id, miembro)
  .then(response => {
      return response.data
    })
}

export function revoke (id) {
  return axios.put(path + '/revoke/' + id)
  .then(response => {
      return response.data
    })
}

export function addRenewal (miembro) {
  return axios.post(path + '/renewal', miembro)
  .then(response => {
    return response.data
  })
}

export function uploadConstanciaFiscal (payload) {
  return axios.post(path + '/upload-constancia',
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).then(response => {
    return response.data
  })
}

export function downloadFile (name) {
  return axios({
    method: 'get',
    url: path + '/download-constancia/' + name,
    responseType: 'blob',
  })
  .then(response => {
      return response
    })
}

export default {
  getAllMiembros,
  getMiembroByToken,
  getMiembroByEmail,
  buscarEmailDuplicado,
  add,
  update,
  revoke,
  addRenewal,
  uploadConstanciaFiscal,
  downloadFile,
}
